import React, { useEffect, useState } from "react";
import { Container, ContainerLogo, Divider, Footer, Header, Main, PoliciesContainer, SelectLanguageButtonContainer, SelectLanguageContainer, TextButton } from "./style";
import { getS3LogoUrl } from '../../utils/s3';
import { isMobileDevice, landscape } from '../../utils/mobile-detect';
import { EndedExam } from "../../components/endedExam";
import { useTranslation } from 'react-i18next';

const DownloadApp = () => {
  const { t, i18n } = useTranslation();
  const [setting, setSetting] = useState({logoUrl: ""});
  const [isLandscape, setIsLandscape] = useState(landscape());

  useEffect(() => {
    getLogoUrl()
  },[])
  
  const getLogoUrl = async () => {
    const logoUrl = await getS3LogoUrl()
    setSetting({logoUrl})
  }

  const verifyOrientation = () => {
      console.log('change orientation')
      setIsLandscape(landscape())
  }

  const handleSupportMessage = (supportMessage) => {
    const phoneNumber = '551124245299'; 
    let message = ''
    if (supportMessage) {
      message = t('supportMessages.messageViewUser');
    }
    else {
      message = t('supportMessages.messageHelp');
    }

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
    mixpanel.track('CALL_SUPPORT_SOLUTION_ASSISTA', { 
      distinct_id: "",
      'CALL_SUPPORT': true,
      'PHONE_SUPPORT': phoneNumber,
      'URL_SUPPORT': whatsappUrl,
      'MESSAGE_SUPPORT': message,
    });
  }

  window.addEventListener("orientationchange", verifyOrientation);

  return (
    <Container>
      <Header> 
        <ContainerLogo url={setting.logoUrl}></ContainerLogo>
      </Header>
      <Main>
        <EndedExam showTitle={false} flowType={0} organization={""} isMobileLandscap={isLandscape} />
      </Main>
      <Footer>
        <TextButton onClick={() => handleSupportMessage(true) }>
          {t('buttons.help')}
        </TextButton>

        <SelectLanguageContainer>
          <span className='selected-language'>{t("changeLan.label")}</span>

          <SelectLanguageButtonContainer>
            <button 
                className='selected-language-button' 
                style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
                onClick={() => { i18n.changeLanguage('pt-BR') }}
            >
                Português
            </button>
            
            <button 
                className='selected-language-button' 
                style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
                onClick={() => { i18n.changeLanguage('en-US') }}
            >
                English
            </button>
            
            <button 
                className='selected-language-button' 
                style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
                onClick={() => { i18n.changeLanguage('es') }}
            >
                Español
            </button>
            </SelectLanguageButtonContainer>
        </SelectLanguageContainer>

        <Divider />

        <PoliciesContainer>
          <a
            href="https://documentos.vlabhealth.com/POLITICADEPRIVACIDADE.pdf"
            target='_blank'
            rel="noopener noreferrer"
            className='terms-link' 
          >
            {`${t('terms.privacyPolitics')}`}
          </a>

          <a
            href="https://documentos.vlabhealth.com/TermosUsoConvidados.pdf"
            target='_blank'
            rel="noopener noreferrer"
            className='terms-link' 
          >
            {`${t('terms.termsOfUse')}`}
          </a>

          <a
            href="https://documentos.vlabhealth.com/TermoDeColetaDeDadosDeSaúde.pdf"
            target='_blank'
            rel="noopener noreferrer"
            className='terms-link' 
          >
            
            {`${t('terms.healthTerms')}`}
          </a>
        </PoliciesContainer>
      </Footer>
    </Container>
  );
};

export default DownloadApp;